import axios from "axios";

import {
  reservationsApi,
  ReservationsApiErrors,
} from "api/services/reservations";
import { MenuItemVariant } from "custom-types/MenuItem";
import logError from "utils/logError";

const logAndReturnError = (
  errors: ReservationsApiErrors,
  status?: string | number,
) => {
  if (
    !errors[0].detail.includes("cart_mismatch_error") &&
    !errors[0].detail.includes("multiple_menu_type_error")
  ) {
    logError(errors[0].detail, {
      functionName: "postReservation",
      service: "reservations",
      statusCode: status || "UNKNOWN",
    });
  }
  return errors[0].detail;
};

const postReservation = async ({
  cartId,
  menuItemId,
  quantity,
  variant,
  forceUpdate,
  brandVerified,
  domainCountryCode,
  inStoreCart,
}: {
  brandVerified: boolean | undefined;
  cartId: string;
  forceUpdate: boolean;
  menuItemId: number;
  quantity: number;
  variant: MenuItemVariant;
  domainCountryCode: "US" | "CA";
  inStoreCart?: boolean;
}): Promise<unknown> => {
  try {
    const { data } = await reservationsApi({
      domainCountryCode: domainCountryCode,
    }).post(
      `/cart_items`,
      {
        cartItem: {
          brand_verified: brandVerified,
          medical: variant.medical,
          menuItemId: menuItemId,
          packagePrice: variant.price,
          packageSize: variant.cartQuantity,
          packageUnit: variant.cartUnit,
          quantity: quantity,
          variantId: variant.id,
        },
        dataApiVersion: "v3",
        forceUpdate,
        ...(inStoreCart && { inStoreCart }),
      },
      {
        headers: {
          "X-Leafly-Id": cartId,
        },
      },
    );

    if (data.errors) {
      throw data.errors;
    }

    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const errorData = e.response?.data as {
        errors?: ReservationsApiErrors;
        error?: string;
        status?: number;
      };

      if (errorData.error || errorData.status) {
        logError(errorData.error || e.message, {
          functionName: "postReservation",
          service: "reservations",
          statusCode:
            errorData.status || e.response?.status || e.code || "UNKNOWN",
        });

        throw new Error(errorData.error || e.message);
      } else if (errorData.errors) {
        const error = logAndReturnError(errorData.errors);

        throw new Error(error);
      } else {
        logError(
          (e.response?.statusText !== "" && e.response?.statusText) ||
            e.message,
          {
            functionName: "postReservation",
            service: "reservations",
            statusCode: e.response?.status || e.code || "UNKNOWN",
          },
        );
        throw new Error(
          (e.response?.statusText !== "" && e.response?.statusText) ||
            e.message,
        );
      }
    } else if (Array.isArray(e)) {
      const error = logAndReturnError(e);

      throw new Error(error);
    } else {
      logError(e.message || "reservationUnknownError", {
        functionName: "postReservation",
        service: "reservations",
        statusCode: e.code || "UNKNOWN",
      });

      throw new Error(e.message || "reservationUnknownError");
    }
  }
};

export default postReservation;
